import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

// Compopnents
import { Container, Row, Col } from "react-bootstrap"

// Styles
import teamStyles from "../pages/team.module.scss"

const TeamMembersPage = ({ justifyLeft, useOpacity }) => {

    const data = useStaticQuery(graphql`
        query TeamMemberQuery {
            logo: file(relativePath: { regex: "/logo_transparent/" }) {
                childImageSharp {
                    fluid
                    {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            allStrapiEmployees(sort: {fields: position}) {
                edges {
                    node {
                        name
                        email
                        position
                        subtitle
                        tel
                        telInternal
                        title
                        urlSlug
                        profilePicture {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    return (

        <Container className={`mainMid my-3`}>
            <Row className={justifyLeft ? `justify-content-left` : `justify-content-center`}>
                {data.allStrapiEmployees.edges.map(edge => {
                    const urlSlug = (edge.node.urlSlug === null ? "" : edge.node.urlSlug)
                    return (

                        <Col xs={6} md={4} lg={2} className={`${teamStyles.link} text-center`}>
                            <Link
                                to={`/team/${urlSlug}/`}
                                title={edge.node.name}
                                // stripHash={true}
                                className={` ${useOpacity ? teamStyles.opacity : ""}`}
                            >
                                <div className={`${teamStyles.teamMember} mx-auto`}>
                                    <Img className={teamStyles.teamMemberImg}
                                        fluid={edge.node.profilePicture === null ? data.logo.childImageSharp.fluid : edge.node.profilePicture.childImageSharp.fluid}
                                    />
                                </div>
                                <span className={teamStyles.teamMemberTitle}>{edge.node.name}</span>
                            </Link>
                        </Col>
                    )
                })}
            </Row>
        </Container>
    )
}

export default TeamMembersPage