import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

// Compopnents
import { Container, Row, Col } from "react-bootstrap"

// Styles
import serviceStyles from "./services.module.scss"

const ServicesPage = ({ useOpacity, justifyLeft }) => {

    const data = useStaticQuery(graphql`
        query ServicesQuery {
            logo: file(relativePath: { regex: "/logo_transparent/" }) {
                childImageSharp {
                    fluid
                    {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            allStrapiServices(sort: {fields: position}) {
                edges {
                    node {
                        position
                        title
                        urlSlug
                        description
                        iconText
                    }
                }
            }
        }
    `)

    return (
        <Container id="menu" className={`mainMid my-1 my-lg-5`}>
            <Row className={justifyLeft ? `justify-content-left` : `justify-content-center`}>
                {data.allStrapiServices.edges.map(edge => {
                    const urlSlug = (edge.node.urlSlug === null ? "" : edge.node.urlSlug)
                    return (
                        <Col xs={6} md={4} lg={2} className={`${serviceStyles.serviceLink} text-center`}>
                            <Link
                                // to={`/angebot/${urlSlug}/#description`}
                                to={`/angebot/${urlSlug}/`}
                                className={` ${useOpacity ? serviceStyles.opacity : ""}`}
                                activeClassName={serviceStyles.active}
                            >
                                <div id="box" className={`${serviceStyles.serviceItem} mx-auto`}>
                                    <small className={serviceStyles.serviceItemText}>{edge.node.title}</small>
                                </div>
                            </Link>
                        </Col>
                    )
                })}
            </Row>
        </Container>
    )
}

export default ServicesPage