import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import TeamMembers from "../components/teamMembers"
import Services from "../components/services"

import { Container, Row, Col } from "react-bootstrap"

// Styles
import indexStyles from "./index.module.scss"


const IndexPage = () => {

    const data = useStaticQuery(graphql`
        query {
            heroBackground: file(relativePath: { regex: "/gruppe/" }) {
                childImageSharp {
                    fluid
                    {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            strapiStart {
                heroSubtitle
                heroTitle
                introDescription
                introTitle
                servicesSubtitle
                servicesTitle
                teamSubtitle
                teamTitle
            }
        }
    `)

    return (
        <Layout>
            <SEO title="Home" />
            <BackgroundImage
                className={`${indexStyles.backgroundImage}`}
                fluid={data.heroBackground.childImageSharp.fluid}
            >
                <Container className={`hero mainMid`} />
            </BackgroundImage>

            <Container className={`mainMid`}>
                <Container className="boxH1">
                    <Row>
                        <Col>
                            <h1>
                                {data.strapiStart.heroTitle}
                            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                {data.strapiStart.heroSubtitle}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-left mb-2">
                            <a href="mailto:info@ptp-m.de" className={`primaryBtn`}>
                                TERMIN VEREINBAREN
                            </a>
                        </Col>
                    </Row>
                </Container>
            </Container>

            {/* Section 1 */}
            <Container className={`mainMid my-5`}>
                <Row>
                    <Col>
                        <h2 className="h2big">
                            {data.strapiStart.introTitle}
                        </h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ReactMarkdown source={data.strapiStart.introDescription} escapeHtml={false} />
                    </Col>
                </Row>

            </Container>

            {/* Section 2 */}
            <Container className={`mainMid my-5`}>
                <Row>
                    <Col>
                        <h2 className="h2big">
                            {data.strapiStart.servicesTitle}
                        </h2>
                    </Col>
                    <Col xs={3} className={`d-none d-md-block p-0 text-right mr-4`}>
                        <Link to="/angebot/" className="noLinkStyling">
                            <div className={`primaryBtn text-center`}>
                                ZUM BERATUNGSANGEBOT
                            </div>
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span>
                            <ReactMarkdown source={data.strapiStart.servicesSubtitle} escapeHtml={false} />
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Services useOpacity={false} justifyLeft={true}/>
                </Row>
            </Container>

            {/* Section 3 */}
            <Container className={`mainMid my-5`}>
                <Row>
                    <Col>
                        <h2 className="h2big">
                            {data.strapiStart.teamTitle}
                        </h2>
                    </Col>
                    <Col xs={3} className={`d-none d-md-block p-0 text-right mr-4`}>
                        <Link to="/team/" className="noLinkStyling">
                            <div className={`primaryBtn text-center noLinkStyling`}>
                                TEAM KENNENLERNEN
                            </div>
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span>
                            <ReactMarkdown source={data.strapiStart.teamSubtitle} escapeHtml={false} />
                        </span>
                    </Col>
                </Row>

                <TeamMembers useOpacity={false} justifyLeft={true}/>
            </Container>

        </Layout >
    )
}

export default IndexPage
